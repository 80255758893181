.referee,
.tablet {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9999;
  &-wrap {
    text-align: center;
    margin-bottom: 45px;
    color: #fff;
    text-shadow: 0 3px 3px rgba(0,0,0,0.16);
    @include lg {
      margin-bottom: calc(45/1920*100vw);
    }
    @include sp {
      margin-bottom: 28px;
    }
    @include xs {
      margin-bottom: calc(28/375*100vw)
    }
    .bigger & {
      display: none;
    }
  }
  &-content {
    position: relative;
    width: 560px;    
    max-width: 100%;
    margin: 0 auto;
    transform: translateY(20%);
    opacity: 0;
    transition: all 1s;
    @include lg {
      width: calc(560/1920*100vw);
    }
    @include sp {
      width: 300px;
    }
    @include xs {
      width: calc(300/375*100vw)
    }
    &.active {
      opacity: 1;
      transform: translateY(0);
    }
    &-score {
      @include absolute(40%,null,null,50%);
      @include flex(space-between,center,nowrap,null);
      transform: translate(-50%,-45%);
      font-family: 'roboto';
      font-weight: 700;
      width: 120px;
      z-index: 2;
      @include lg {
        width: calc(120/1920*100vw);
      }
      > * {
        margin: 0;
        font-size: 3.6rem;
        color: #BEC4D1;
        width: 48px;
        @include lg {
          width: calc(48/1920*100vw);
        }
      }
      &-left {
        text-align: right;
      }
      &-right {
        text-align: left;
      }
      .active {
        font-size: 4.2rem;
        color: #fff;
      }
      &-center {
        @include absolute(50%,null,null,50%);
        transform: translate(-50%,-50%);
        &:before {
          content: "";
          width: 10px;
          height: 1px;
          display: block;
          background: #fff;
          margin: 0 auto;
          @include lg {
            width: calc(10/1920*100vw);
          }
        }
      }
      @include sp {
        width: 60px;
        min-width: auto;
        top: 34%;
        > * {
          font-size: 1.8rem;
          width: 24px;
          min-width: auto;
        }
        .active {
          font-size: 2.3rem;
        }
        &-center {
          &:before {
            min-width: 5px
          }
        }
      }
      @include xs {
        width: calc(60/375*100vw);
        > * {
          width: calc(24/375*100vw);
        }
        &-center {
          &:before {
            min-width: calc(5/375*100vw)
          }
        }
      }
    }

    &-buttons {
      @include absolute(null,null,30%,50%);
      @include flex(space-between,center,nowrap,null);
      transform: translate(-50%,3%);
      width: 100%;
      padding: 0 57px;
      z-index: 3;
      @include lg {
        padding: 0 calc(57/1920*100vw);
      }
      @include sp {
        padding: 0 31px;
        bottom: 41%;
      }
      @include xs {
        padding: 0 calc(31/375*100vw);
      }
      &.active {
        z-index: 5;
      }
      &-button {
        border: 0;
        outline: 0;
        width: 60px;
        height: 54px;
        background-color: #EA4645;
        color: #fff;
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 1.11rem;
        cursor: pointer;
        padding: 0;
        transition: all 0.4s;
        span {
          @include flex(center,center,nowrap,null);
          &:before {
            content: "";
            width: 1em;
            height: 1em;
            background: url(../../assets/images/icons/icon_score_button.svg) center center no-repeat;
            background-size: contain;
            margin-right: 2px;
          }
        }

        @include lg {
          width: calc(60/1920*100vw);
          height: calc(54/1920*100vw);
        }
        @include lg(768px) {
          &:hover {
            transform: scale(1.1);
          }
        }
        @include sp {
          width: 33px;
          min-width: auto;
          height: 31px;
          min-height: auto;
          font-size: 60%;
          span {
            transform: scale(0.7);
          }
        }
        @include xs {
          width: calc(33/375*100vw);
          height: calc(31/375*100vw);
          border-radius: calc(3/375*100vw);
        }
      }
    }

    &-image {
      width: 100%;
      filter: drop-shadow(1rem 1rem 2rem rgba(255,255,255,0.44));
      position: relative;
      z-index: 1;
      &-caption {
        text-align: right;
        margin-top: 1em;
        font-size: 1.2rem;
        line-height: calc(16/12);
        color: #fff;
        text-shadow: 0 3px 3px rgba(0,0,0,0.16);
        span {
          display: block;
        }
        .bigger & {
          visibility: hidden;
        }
        @include sp {
          font-size: 1rem;
          color: #000;
          text-shadow: none;
          text-align: left;
          span {
            @include flex(flex-start,flex-start,nowrap,null);
            &:before {
              content: "・";
            }
          }
        }
      }
    }
  }
}

@keyframes refereeDown {
  0% {
    transform: translate(-50%, -50%);
    // opacity: 0;
  }  
  100% {
    transform: translate(-50%,-45%);
    // opacity: 1;
  }
}

@keyframes refereeFadeOut {
  0% {
    transform: translate(-50%,-45%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%,-60%);
    opacity: 0;
  }
}