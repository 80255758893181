.contact {
  position: relative;
  background: url("../images/contact_background.jpg") #fff bottom right no-repeat;
  background-size: cover;
  z-index: 2;
  overflow: hidden;
  @include sp {
    background-image: url(../images/contact_background_sp.jpg);
    background-size: 100% auto;
  }
  &-form {
    width: 1450px;
    max-width: 100%;
    padding: 200px 40px 0;
    margin: 0 auto 108px;
    overflow: hidden;
    @include lg {
      width: calc(1450 / 1920 * 100vw);
      padding: calc(200 / 1920 * 100vw) calc(40 / 1920 * 100vw) 0;
      margin: 0 auto calc(108 / 1920 * 100vw);
    }
    @include sm {
      padding-top: 120px;
    }
    @include sp {
      padding: 160px 20px 0;
      margin-bottom: 50px;
    }
    @include xs {
      padding: calc(160 / 375 * 100vw) calc(20 / 375 * 100vw) 0;
      margin-bottom: calc(50 / 375 * 100vw);
    }

    &-wrap {
      width: 690px;
      max-width: 52%;
      opacity: 0;
      transform: translateY(-30%);
      transition: all 1.5s;
      &.active {
        transform: translate(0, 0);
        opacity: 1;
      }
      @include lg {
        width: calc(690 / 1920 * 100vw);
      }
      @include sp(1280px) {
        text-align: center;
        max-width: 100%;
        order: 1;
      }
    }

    &-policy {
      width: 650px;
      max-width: 48%;
      height: 170px;
      overflow-y: auto;
      @include absolute(null, null, 50px, 0);
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 30px 20px;
      line-height: calc(24 / 16);
      letter-spacing: 0.01em;
      @include lg {
        width: calc(650 / 1920 * 100vw);
        height: calc(170 / 1920 * 100vw);
        bottom: calc(50 / 1920 * 100vw);
        padding: calc(30 / 1920 * 100vw) calc(20 / 1920 * 100vw);
      }
      @include sp(1280px) {
        max-width: 100%;
        padding: 20px;
        order: 3;
        position: relative;
        bottom: auto;
      }
      @include xs {
        padding: calc(20 / 375 * 100vw);
      }
      &-title {
        font-size: 1.8rem;
        margin-bottom: 2em;
        font-weight: 500;
        @include sp {
          font-size: 1.6rem;
        }
      }
      &-description {
        font-size: 1.6rem;
        small {
          display: block;
          font-size: 1.4rem;
          margin-top: 2em;
        }
        @include sp {
          font-size: 1.4rem;
          small {
            font-size: 1.2rem;
          }
        }
      }
      &-subtitle {
        margin: 2em auto 1em;
        font-size: 1.6rem;
        font-weight: 500;
        @include sp {
          font-size: 1.4rem;
        }
      }
      &-list {
        font-size: 1.4rem;
        margin: 1em auto;
        li {
          @include flex(flex-start, flex-start, nowrap, null);
          span {
            margin-right: 0.5em;
          }
        }
        @include sp {
          font-size: 1.2rem;
        }
      }
      &-footer {
        font-size: 1.6rem;
        text-align: right;
        margin-top: 3em;
      }
    }

    &-content {
      position: relative;
      @include flex(space-between, flex-start, nowrap, null);
      @include sp(1280px) {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &-detail {
      width: 650px;
      max-width: 46%;
      margin-top: 50px;
      @include lg {
        width: calc(650 / 1920 * 100vw);
        margin-top: calc(50 / 1920 * 100vw);
      }
      @include sp(1440px) {
        max-width: 100%;
        margin-top: 50px;
        order: 2;
      }
      @include xs {
        margin-top: calc(30 / 375 * 100vw);
      }
      .MuiFormControl-root {
        padding-bottom: 50px;
        @include lg {
          padding-bottom: calc(50 / 1920 * 100vw);
        }
        @include sp {
          padding-bottom: 30px;
        }
        @include xs {
          padding-bottom: calc(30 / 375 * 100vw);
        }
      }
      input,
      label,
      textarea {
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        @include sp {
          font-size: 1.4rem;
        }
      }
      .MuiFilledInput-root {
        &:before {
          border-color: #bfbfbf;
        }
      }
      .MuiFormHelperText-root {
        @include absolute(null, null, 20px, 0);
        font-size: 1.2rem;
        @include lg {
          bottom: calc(20 / 1920 * 100vw);
        }
        @include sp {
          bottom: 4px;
        }
        @include xs {
          bottom: calc(4 / 375 * 100vw);
        }
      }
      label {
        &.MuiInputLabel-root {
          transform: translate(15px, 24px) scale(1);
          @include lg {
            transform: translate(calc(15 / 1920 * 100vw), calc(24 / 1920 * 100vw)) scale(1);
          }
          @include sp {
            transform: translate(15px, 20px) scale(1);
          }
        }
        &.MuiFormLabel-filled,
        &.Mui-focused {
          transform: translate(15px, 12px) scale(0.75);
          @include lg {
            transform: translate(calc(15 / 1920 * 100vw), calc(12 / 1920 * 100vw)) scale(0.75);
          }
          @include sp {
            transform: translate(15px, 10px) scale(0.7);
          }
        }
      }
      input {
        &.MuiFilledInput-input {
          padding: 33px 15px 14px;
          @include lg {
            padding: calc(33 / 1920 * 100vw) calc(15 / 1920 * 100vw) calc(14 / 1920 * 100vw);
          }
          @include sp {
            padding: 30px 15px 10px;
          }
        }
      }
      textarea {
        &.MuiFilledInput-input {
          padding: 10px 3px;
          @include lg {
            padding: calc(10 / 1920 * 100vw) calc(3 / 1920 * 100vw);
          }
        }
      }
    }

    .MuiTypography-root {
      font-size: 1.8rem;
      @include sp {
        font-size: 1.6rem;
      }
    }

    .MuiSvgIcon-root {
      font-size: 2.8rem;
      @include sp {
        font-size: 2rem;
      }
    }

    &-footer {
      overflow: hidden;
      @include flex(center, center, wrap, column);
      @include sp(1280px) {
        margin-top: 30px;
      }
      > * {
        margin-bottom: 30px;
        @include lg {
          margin-bottom: calc(30 / 1920 * 100vw);
        }
      }
      .MuiCheckbox-root {
        color: #ddd;
        &.Mui-checked {
          color: $color-primary;
        }
      }
    }
  }

  &-submit {
    padding: 200px 40px 0;
    margin: 0 auto 108px;
    overflow: hidden;
    text-align: center;
    opacity: 0;
    transform: translateY(-5%);
    animation: onSubmit 2s forwards;
    @include lg {
      width: calc(1450 / 1920 * 100vw);
      padding: calc(200 / 1920 * 100vw) calc(40 / 1920 * 100vw) 0;
      margin: 0 auto calc(108 / 1920 * 100vw);
    }
    @include sm {
      padding-top: 120px;
    }
    @include sp {
      width: 624px;
      max-width: 100%;
      padding: 160px 20px 0;
      margin-bottom: 50px;
      text-align: left;
    }
    @include xs {
      padding: calc(160 / 375 * 100vw) calc(20 / 375 * 100vw) 0;
      margin-bottom: calc(50 / 375 * 100vw);
    }
    &-title {
      text-align: center;
      @include sp {
        margin: 0 -20px;
        padding-left: 20px;
      }
      @include xs {
        margin: 0 calc(-20 / 375 * 100vw);
        padding-left: calc(20 / 375 * 100vw);
      }
    }
    &-description {
      letter-spacing: 0.04em;
      line-height: 1.425;
    }
    &-list {
      font-size: 1.6rem;
      background: rgba(0, 0, 0, 0.05);
      padding: 1.5em 1em;
      text-align: left;
      display: inline-block;
      li {
        margin: 0.5em auto;
        @include flex(flex-start, flex-start, nowrap, null);
        &:before {
          content: "・";
          margin-right: 0.5em;
        }
      }
    }
    &-footer {
      text-align: center;
      margin-top: 60px;
    }
  }

  .MuiButton-root {
    font-size: 1.8rem;
    width: 250px;
    padding: 16px;
    background-color: $color-primary;
    @include lg {
      width: calc(250 / 1920 * 100vw);
      padding: calc(16 / 1920 * 100vw);
    }
    &:hover,
    &:focus {
      background-color: #0c1960;
    }
  }
}

@keyframes onSubmit {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
