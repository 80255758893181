@import "preset";

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin fixed($top: null, $right: null, $bottom: null, $left: null) {
  position: fixed;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flex($justify-content: null, $align-items: null, $wrap: null, $direction: null) {
  display: flex;
  align-content: center;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $wrap;
  flex-direction: $direction;
}
@mixin lg($min: 1921px) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin md($min: 768px, $max: 1440px) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin sm($min: 768px, $max: 1280px) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin sp($max: 767px) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin xs($max: 360px) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin media($min: null, $max: null) {
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin noto-sans {
  font-family: "Noto Sans JP", sans-serif;
}

@mixin roboto {
  font-family: "Roboto", sans-serif;
}

@mixin linkArrow($color) {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6.84" height="12" viewBox="0 0 6.84 12"><path d="M13.084,15.9,12.4,14.567,17.872,9.9,12.4,4.567l.684-.667,6.156,6Z" transform="translate(-12.4 -3.9)" fill="%23#{$color}"/></svg>');
}
