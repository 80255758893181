.header {
  width: 100%;
  overflow: hidden;
  z-index: 100;
  @include absolute(20px, null, null, 0);
  height: 90px;
  &.fixed {
    position: fixed;
  }
  @include lg {
    height: calc(100 / 1920 * 100vw);
    top: calc(20 / 1920 * 100vw);
  }
  @include sm {
    height: 70px;
    top: 20px;
  }
  @include sp {
    height: 60px;
    top: 10px;
  }

  &-logo {
    @include absolute(50%, null, null, 50%);
    transform: translate(-50%, -50%);
    width: 350px;

    a {
      width: 100%;
      display: block;
      cursor: pointer;
    }

    .fixed & {
      top: 0;
      left: 0;
      transform: unset;
      width: 420px;
      a {
        @include flex(flex-start, center, nowrap, null);
        width: 100%;
        height: 80px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 0 40px 40px 0;
        transition: filter 0.4s;
        transform: translateX(-100%);
        animation: slideLogo 0.4s forwards;
        filter: drop-shadow(3px 3px 5px rgba(183, 183, 183, 0.1));

        img {
          width: 300px;
          margin-left: 20px;
        }
        &:hover {
          filter: drop-shadow(5px 5px 0 rgba(0, 0, 0, 0.1));
        }
      }
    }

    @include lg {
      width: calc(350 / 1920 * 100vw);
      .fixed & {
        width: calc(420 / 1920 * 100vw);

        a {
          height: calc(80 / 1920 * 100vw);
          border-radius: 0 calc(40 / 1920 * 100vw) calc(40 / 1920 * 100vw) 0;
          img {
            width: calc(300 / 1920 * 100vw);
            margin-left: calc(20 / 1920 * 100vw);
          }
        }
      }
    }

    @include sm {
      width: 300px;
      top: 40%;
      .fixed & {
        a {
          height: 70px;
          width: 380px;
          img {
            width: 280px;
          }
        }
      }
    }

    @include sp {
      width: 100%;
      transform: unset;
      position: fixed;
      top: 10px !important;
      left: 0;
      a {
        @include flex(flex-start, center, nowrap, null);
        height: 50px !important;
        width: 250px !important;
        transform: translateX(0) !important;
        animation: none !important;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 0 25px 25px 0;
        img {
          width: 180px !important;
          margin-left: 20px !important;
        }
      }
    }

    @include xs {
      top: calc(10 / 375 * 100vw) !important;
      a {
        height: calc(50 / 375 * 100vw) !important;
        width: calc(250 / 375 * 100vw) !important;
        border-radius: 0 calc(25 / 375 * 100vw) calc(25 / 375 * 100vw) 0;
        img {
          width: calc(180 / 375 * 100vw) !important;
          margin-left: calc(20 / 375 * 100vw) !important;
        }
      }
    }
  }

  &-navigation {
    overflow: hidden;
    @include absolute(50%, 28px, null, null);

    @include lg {
      right: calc(28 / 1920 * 100vw);
    }

    &-list {
      @include flex(space-between, center, nowrap, null);
      &-item {
        margin: 0 15px;
        a {
          font-size: 1.6rem;
          padding: 7px 10px;
          color: #fff;
          position: relative;
          display: block;
          cursor: pointer;
          &:after {
            content: "";
            width: 0;
            height: 1px;
            background-color: #fff;
            @include absolute(null, null, 0, 0);
            display: block;
            transition: all 0.4s;
          }
          &:hover {
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
    &-logo {
      display: none;
    }

    .fixed &,
    &.fixed {
      position: fixed;
      top: 20px;
      right: 20px;
      width: 300px;
      height: 280px;
      background: #fff;
      border-radius: 35px;
      z-index: 300;
      padding: 40px 40px 50px 40px;
      opacity: 0;
      transform: scale(0);
      transform-origin: 90% 5%;
      @include lg {
        width: calc(300 / 1920 * 100vw);
        height: calc(280 / 1920 * 100vw);
      }
      .header-navigation-list {
        height: 100%;
        align-content: flex-end;
        flex-wrap: wrap;
        margin: auto 0;
        &-item {
          margin: 0;
          width: 100%;
          text-align: right;
          a {
            padding: 1em 0;
            letter-spacing: 0.01em;
            font-size: 1.8rem;
            color: $color-primary;
            font-weight: bold;
            @include flex(flex-end, center, nowrap, null);
            span {
              color: #000;
            }
            &:after {
              background-color: $color-primary;
              height: 2px;
            }
            &:hover {
              color: $color-primary;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.active {
      opacity: 1;
      transform: scale(1);
      transform-origin: top right;
      transition: all 0.4s;
      // animation: slideNav 0.6s forwards;
    }

    @include lg {
      top: calc(20 / 1920 * 100vw);
      right: calc(20 / 1920 * 100vw);
      border-radius: calc(35 / 1920 * 100vw);
      padding: calc(40 / 1920 * 100vw) calc(40 / 1920 * 100vw) calc(50 / 1920 * 100vw) calc(40 / 1920 * 100vw);
    }

    @include sm {
      transform: scale(0);
    }

    @include sp {
      z-index: 200;

      &-logo {
        display: block;
        width: 100%;
        transition: all 0.4s;
        opacity: 0;
        .active & {
          opacity: 1;
        }
      }
      .fixed &,
      &.fixed {
        top: 10px;
        right: 10px;
        width: 240px;
        height: 300px;
        border-radius: 25px;
        padding: 20px;
        @include flex(center, center, nowrap, column);
        .header-navigation-list {
          align-content: center;
          &-item {
            a {
              font-size: 1.6rem;
              justify-content: flex-end;
            }
          }
        }
      }
    }

    @include xs {
      .fixed &,
      &.fixed {
        top: calc(10 / 375 * 100vw);
        right: calc(10 / 375 * 100vw);
        width: calc(240 / 375 * 100vw);
        height: calc(300 / 375 * 100vw);
        border-radius: calc(25 / 375 * 100vw);
        padding: calc(20 / 375 * 100vw);
      }
    }
  }

  &-toggle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    @include absolute(0, 20px, null, null);
    @include flex(center, center, nowrap, null);
    transform: scale(0);
    transform-origin: top right;
    transition: all 0.4s;
    cursor: pointer;

    &-button {
      width: 38px;
      height: 30px;
      position: relative;
      span {
        width: 100%;
        height: 3px;
        background: #fff;
        display: block;
        transition: all 0.4s;
        border-radius: 2px;
      }
      .top {
        @include absolute(0, null, null, 0);
      }
      .middle {
        @include absolute(50%, null, null, 0);
        transform: translateY(-50%);
      }
      .bottom {
        @include absolute(null, null, 0, 0);
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.5);
      span {
        background: $color-primary;
      }
    }

    &.active {
      span {
        background: $color-primary;
      }

      .top {
        top: 45%;
        transform: translateY(-45%) rotate(45deg);
      }
      .middle {
        display: none;
      }
      .bottom {
        bottom: 45%;
        transform: translateY(-45%) rotate(-45deg);
      }
    }

    .fixed & {
      transform: scale(1);
      z-index: 333;
    }

    @include lg {
      width: calc(70 / 1920 * 100vw);
      height: calc(70 / 1920 * 100vw);
      // top: calc(20 / 1920 * 100vw);
      right: calc(20 / 1920 * 100vw);
      &-button {
        width: calc(38 / 1920 * 100vw);
        height: calc(30 / 1920 * 100vw);
      }
    }

    @include sm {
      position: fixed;
      top: 20px;
      transform: scale(1);
      z-index: 333;
    }

    @include sp {
      @include fixed(10px, 10px, null, null);
      z-index: 333;
      cursor: pointer;
      width: 50px;
      height: 50px;
      transform: scale(1);
      background: rgba(255, 255, 255, 0.6);
      filter: drop-shadow(3px 3px 5px rgba(183, 183, 183, 0.1));

      &-button {
        width: 20px;
        height: 16px;
      }
      span {
        height: 2px;
        background: $color-primary;
      }
    }

    @include xs {
      width: calc(50 / 375 * 100vw);
      height: calc(50 / 375 * 100vw);
      &-button {
        width: calc(20 / 375 * 100vw);
        height: calc(16 / 375 * 100vw);
      }
      span {
        height: calc(2 / 375 * 100vw);
      }
    }
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideNav {
  0% {
    transform: translateX(100%) scale(0);
    transform-origin: right top;
  }
  100% {
    transform: translateX(0) scale(1);
    transform-origin: right top;
  }
}

@keyframes slideNavHidden {
  0% {
    transform: translateX(0) scale(1);
    transform-origin: right top;
  }
  100% {
    transform: translateX(100%) scale(0);
    transform-origin: right top;
  }
}

@keyframes slideLogo {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
