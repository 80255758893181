.works {
  margin-top: 200px;
  position: relative;
  z-index: 4;
  @include lg {
    margin-top: calc(200 / 1920 * 100vw);
  }
  @include sp {
    margin-top: 160px;
  }
  @include xs {
    margin-top: calc(160 / 375 * 100vw);
  }
  &-wrap {
    opacity: 0;
    transform: translateY(-30%);
    transition: all 1.5s;
    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &-title,
  &-description {
    text-align: center;
  }
  &-slide {
    margin: 70px auto 0;
    padding-bottom: 80px;
    @include lg {
      margin-top: calc(70 / 1920 * 100vw);
      padding-bottom: calc(80 / 1920 * 100vw);
    }
    @include sp {
      margin-top: 30px;
      padding-bottom: 60px;
    }
    @include xs {
      margin-top: calc(30 / 375 * 100vw);
      padding-bottom: calc(60 / 375 * 100vw);
    }
    &.unslick {
      .slick-track {
        @include flex(center, center, nowrap, null);
      }
    }
    .slick {
      &-slide {
        > div {
          width: 730px;
          max-width: 100%;
          margin: 0 40px;
          @include lg {
            width: calc(730 / 1920 * 100vw);
            margin: 0 calc(40 / 1920 * 100vw);
          }
          @include sm {
            width: 640px;
            margin: 0 25px;
          }
          @include sp {
            width: 335px;
            margin: 0 10px;
          }
          @include xs {
            width: calc(335 / 375 * 100vw);
            margin: 0 calc(10 / 375 * 100vw);
          }
        }
      }
      &-disabled {
        display: none;
      }
    }
    &-item {
      border: #efefef 1px solid;
      background: #fff;
      margin-bottom: 10px;
      filter: drop-shadow(10px 10px 0 rgba(235, 235, 235, 0.75));
      transform: translateZ(0);
      border-radius: 20px;
      @include lg {
        margin-bottom: calc(10 / 1920 * 100vw);
        border-radius: calc(20 / 1920 * 100vw);
      }
      @include sp {
        border-radius: 15px;
      }
      @include xs {
        border-radius: calc(15 / 375 * 100vw);
      }
      &-header {
        position: relative;
        color: #fff;
        background: $color-primary;
        padding: 36px 50px;
        border-radius: 20px 20px 0 0;
        overflow: hidden;
        height: 156px;
        &:before {
          content: "";
          width: 46%;
          height: 100%;
          background: #fff;
          opacity: 0.05;
          @include absolute(0, null, null, 0);
        }
        &:after {
          content: "";
          border-style: solid;
          border-width: 170px 0 0 120px;
          border-color: transparent transparent transparent #fff;
          opacity: 0.05;
          @include absolute(0, null, null, 46%);
        }
        @include lg {
          padding: calc(36 / 1920 * 100vw) calc(50 / 1920 * 100vw);
          border-radius: calc(20 / 1920 * 100vw) calc(20 / 1920 * 100vw) 0 0;
          height: calc(156 / 1920 * 100vw);
          &:after {
            border-width: calc(170 / 1920 * 100vw) 0 0 calc(120 / 1920 * 100vw);
          }
        }
        @include sm {
          padding: 30px 40px;
          height: 133px;
        }
        @include sp {
          height: 75px;
          padding: 15px 20px;
          border-radius: 15px 15px 0 0;
        }
        @include xs {
          height: calc(75 / 375 * 100vw);
          padding: calc(15 / 375 * 100vw) calc(20 / 375 * 100vw);
          border-radius: calc(15 / 375 * 100vw) calc(15 / 375 * 100vw) 0 0;
        }
      }
      &-title {
        font-size: 3rem;
        font-weight: bold;
        line-height: calc(42 / 30);
        letter-spacing: 0.01em;
        white-space: pre-wrap;
        @include flex(flex-start, center, nowrap, null);
        span {
          width: 540px;
        }
        &:before {
          content: "";
          width: 60px;
          height: 68px;
          background: url(../images/icons/icon_shuttle_red.svg) top center no-repeat;
          background-size: contain;
          margin-right: 1em;
        }
        @include lg {
          span {
            width: calc(540 / 1920 * 100vw);
          }
          &:before {
            width: calc(60 / 1920 * 100vw);
            height: calc(68 / 1920 * 100vw);
          }
        }
        @include sm {
          font-size: 2.6rem;
          &:before {
            width: 50px;
            height: 58px;
          }
        }
        @include sp {
          font-size: 1.6rem;
          span {
            width: 265px;
          }
          &:before {
            width: 20px;
            height: 22px;
          }
        }
      }
      &-content {
        padding: 40px 50px;
        font-size: 1.8rem;
        position: relative;
        min-height: 310px;
        @include lg {
          padding: calc(40 / 1920 * 100vw) calc(50 / 1920 * 100vw);
          min-height: calc(310 / 1920 * 100vw);
        }
        @include sm {
          padding: 30px 40px;
          min-height: 280px;
        }
        @include sp {
          font-size: 1rem;
          padding: 20px 20px 15px;
          min-height: 173px;
        }
        @include xs {
          padding: calc(20 / 375 * 100vw) calc(20 / 375 * 100vw) calc(15 / 375 * 100vw);
          min-height: calc(173 / 375 * 100vw);
        }
        &:after {
          content: "";
          width: 112px;
          height: 110px;
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="111.91" height="109.694" viewBox="0 0 111.91 109.694"><path d="M134.72,440.046c-.014-13.277-11.105-24.2-24.377-24.377H103.7a31.09,31.09,0,0,0,4.432-17.728c0-11.359-6.129-18.836-15.512-18.836S77.1,386.582,77.1,397.941a31.193,31.193,0,0,0,4.432,17.728c-2.059.392-3.446-.1-5.54,0-12.712.084-22.475,10.121-24.377,24.377l-2.216,13.3L39.43,443.37H22.81l8.864,8.864h0l26.593,26.593,5.54-37.673c.517-3.973,2.525-13.3,12.188-13.3h0a45.861,45.861,0,0,0,16.62-3.324c2.827,1.275,5.819,1.632,8.864,2.216h0a50.819,50.819,0,0,0,7.756,1.108h1.108a12.157,12.157,0,0,1,12.188,12.188V465.53h-7.756V453.342H70.455V488.8h44.321V476.611H134.72Zm-42.1-29.917c-3.2-3.4-3.324-7.879-3.324-12.188,0-1.189-.354-6.648,3.324-6.648s4.432,5.459,4.432,6.648A17.57,17.57,0,0,1,92.615,410.129Zm-11.08,66.481V465.53H103.7v11.08Z" transform="translate(-22.81 -379.104)" fill="%23f1f1f1"/></svg>')
            0 0 no-repeat;
          background-size: contain;
          @include absolute(null, 50px, 40px, null);
          opacity: 0.3;
          @include lg {
            width: calc(112 / 1920 * 100vw);
            height: calc(110 / 1920 * 100vw);
            right: calc(50 / 1920 * 100vw);
            bottom: calc(40 / 1920 * 100vw);
          }
          @include sp {
            width: 58px;
            height: 55px;
            right: 20px;
            bottom: auto;
            top: 14px;
          }
          @include xs {
            width: calc(58 / 375 * 100vw);
            height: calc(55 / 375 * 100vw);
            right: calc(20 / 375 * 100vw);
            top: calc(14 / 375 * 100vw);
          }
        }
      }
      &-detail {
        li {
          @include flex(flex-start, flex-start, nowrap, null);
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          &:before {
            background: #eee;
            width: 105px;
            text-align: center;
            margin-right: 50px;
            padding: 5px 0 7px;
            line-height: 1;
          }
          @include lg {
            &:not(:last-child) {
              margin-bottom: calc(10 / 1920 * 100vw);
            }
            &:before {
              margin-right: calc(50 / 1920 * 100vw);
              padding: calc(5 / 1920 * 100vw) 0 calc(7 / 1920 * 100vw);
              width: calc(105 / 1920 * 100vw);
            }
          }
          @include sp {
            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &:before {
              margin-right: 11px;
              padding: 4px 0;
              width: 60px;
            }
          }
          @include xs {
            &:not(:last-child) {
              margin-bottom: calc(8 / 375 * 100vw);
            }

            &:before {
              margin-right: calc(11 / 375 * 100vw);
              padding: calc(4 / 375 * 100vw) 0;
              width: calc(60 / 375 * 100vw);
            }
          }
        }
        &-date {
          &:before {
            content: "開催日時";
          }
          .data {
            padding: 5px 0 0;
            white-space: pre-wrap;
          }
        }
        &-place {
          .data {
            width: 470px;
            // padding: 5px 0 0;
            line-height: 1.4;
            white-space: pre-wrap;
            @include lg {
              width: calc(470 / 1920 * 100vw);
            }
            @include sm {
              width: 400px;
            }
            @include sp {
              width: 220px;
            }
            @include xs {
              width: calc(220 / 375 * 100vw);
            }
          }
          &:before {
            content: "開催場所";
          }
        }
      }
      &-links {
        @include flex(flex-start, flex-start, wrap, null);
        margin-top: 32px;
        @include lg {
          margin-top: calc(32 / 1920 * 100vw);
        }
        @include sm {
          margin-top: 22px;
        }
        @include sp {
          margin-top: 16px;
        }
        @include xs {
          margin-top: calc(16 / 375 * 100vw);
        }
        .item {
          margin: 8px;
          @include lg {
            margin-top: calc(8 / 1920 * 100vw);
          }
          @include sp {
            margin: 5px;
          }
          @include xs {
            margin: calc(5 / 375 * 100vw);
          }
          a {
            @include flex(space-between, center, nowrap, null);
            padding: 14px 20px 14px 28px;
            width: 200px;
            color: $color-secondary;
            border: 1px solid $color-secondary;
            border-radius: 30px;
            font-weight: bold;
            position: relative;
            overflow: hidden;
            z-index: 1;

            @include lg {
              width: calc(200 / 1920 * 100vw);
              padding: calc(14 / 1920 * 100vw) calc(20 / 1920 * 100vw) calc(14 / 1920 * 100vw) calc(28 / 1920 * 100vw);
              border-radius: calc(30 / 1920 * 100vw);
            }

            @include sp {
              padding: 7px 14px 8px;
              width: 130px;
            }

            @include xs {
              padding: calc(7 / 375 * 100vw) calc(14 / 375 * 100vw) calc(8 / 375 * 100vw);
              width: calc(130 / 375 * 100vw);
            }

            span {
              position: relative;
              transition: all 0.4s;
              z-index: 2;
            }
            &:before {
              content: "";
              width: 0;
              height: 100%;
              background: $color-secondary;
              z-index: 1;
              @include absolute(0, null, null, 0);
              transition: width 0.4s;
            }
            &:after {
              content: "";
              width: 7px;
              height: 12px;
              @include linkArrow("ca1138");
              transition: all 0.4s;
              background-size: contain;
              position: relative;
              z-index: 2;
              @include lg {
                width: calc(7 / 1920 * 100vw);
                height: calc(12 / 1920 * 100vw);
              }
              @include xs {
                width: calc(7 / 375 * 100vw);
                height: calc(12 / 375 * 100vw);
                min-width: calc(7 / 375 * 100vw);
                height: calc(12 / 375 * 100vw);
              }
            }
            &:hover {
              color: #fff;
              &:before {
                width: 100%;
              }
              &:after {
                @include linkArrow("fff");
              }
            }
          }
        }
      }
    }
    &-page {
      display: none;
      @include sp {
        @include flex(center, center, nowrap, null);
        @include absolute(null, null, 12px, 50%);
        transform: translateX(-50%);
        font-size: 1.4rem;
        color: $color-primary;
        &-all {
          &:before {
            content: "/";
            margin: 0 0.5em;
          }
        }
      }
    }
  }
}
