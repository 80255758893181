.loader {
  width: calc(100vw - (100vw - 100%));
  height: 100%;
  min-height: 100vh;
  z-index: 9999 !important;
  overflow: hidden;
  background: #fff;
  @include fixed(0,0,0,0);
  animation: opacityChange 1s 1.5s forwards;
  &-wrap {
    width: 600px;
    max-width: 75%;
    @include absolute(45%,null,null,50%);
    transform: translate(-50%,-50%);
  }
  &-logo {
    position: relative;
    z-index: 2;
    &-color01 {
      fill: #231815;
      animation: fillWhite01 2s 0.5s forwards
    }
    &-color02 {
      fill: #1f318e;
      animation: fillWhite02 2s 0.5s forwards
    }
    &-color03 {
      fill: #000;
      animation: fillWhite03 2s 0.5s forwards
    }
    &-color04 {
      fill: #ca1138;
      animation: fillWhite04 2s 0.5s forwards
    }
  }
  &-spinner {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    opacity: 1;
    svg {
      fill: $color-primary;
      animation: fillWhite02 2s 0.5s forwards;
    }
  }
  &-background {
    @extend .main-background;
    opacity: 0.1;
    // animation: opacityChange 2s forwards;
  }
}

@keyframes opacityChange {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fillWhite01 {
  0% {
    fill: #231815;
  }
  100% {
    fill: #fff;
  }
}
@keyframes fillWhite02 {
  0% {
    fill: #1f318e;
  }
  100% {
    fill: #fff;
  }
}
@keyframes fillWhite03 {
  0% {
    fill: #000;
  }
  100% {
    fill: #fff;
  }
}
@keyframes fillWhite04 {
  0% {
    fill: #ca1138;
  }
  100% {
    fill: #fff;
  }
}