.feature {
  // margin-top: 1512px;
  position: relative;
  z-index: 5;
  margin-top: 200px;
  @include lg {
    margin-top: calc(200 / 1920 * 100vw);
  }
  @include sp {
    margin-top: 160px;
  }
  @include xs {
    margin-top: calc(160 / 375 * 100vw);
  }
  &-title {
    text-align: center;
    opacity: 0;
    transform: translateY(-30%);
    transition: all 1.5s;
    &.active {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
  &-slide {
    margin: 70px auto 0;
    padding-bottom: 80px;
    @include lg {
      margin-top: calc(70 / 1920 * 100vw);
      padding-bottom: calc(80 / 1920 * 100vw);
    }
    @include sp {
      margin-top: 30px;
      padding-bottom: 60px;
    }
    @include xs {
      margin-top: calc(30 / 375 * 100vw);
      padding-bottom: calc(60 / 375 * 100vw);
    }
    .slick {
      &-track {
        left: calc(480 * 1.5 / 1920 * 100vw);
      }
      &-slide {
        width: 455px;
        margin: 0 25px;
        cursor: default;
        @include lg {
          width: calc(455 / 1920 * 100vw);
          margin: 0 calc(25 / 1920 * 100vw);
        }
      }
      @include sm {
        &-track {
          left: calc(420 / 1280 * 100vw);
        }
        &-slide {
          width: 400px;
          margin: 0 20px;
        }
      }
      @include sp {
        &-track {
          left: calc(37 / 375 * 100vw);
        }
        &-slide {
          width: calc(300 / 375 * 100vw);
          margin: 0 calc(10 / 375 * 100vw);
        }
      }
    }
    &-item {
      &-image {
        height: 300px;
        background-color: #fff;
        border-radius: 16px;
        border: #efefef solid 1px;
        box-shadow: 7px 7px 0 rgba(235, 235, 235, 0.75);
        @include flex(center, center, nowrap, null);
        @include lg {
          height: calc(300 / 1920 * 100vw);
        }
        @include sm {
          height: 280px;
        }
        @include sp {
          height: 195px;
        }
        @include xs {
          height: calc(195 / 375 * 100vw);
        }
        img {
          margin: 0 auto;
          transform: scale(0.8);
          transition: all 1s;
          @include lg {
            transform: scale(1);
          }
          @include sm {
            transform: scale(0.7);
          }
          @include sp {
            transform: scale(0.6);
          }
        }
        &.active {
          img {
            transform: scale(1);
            @include lg {
              transform: scale(1.5);
            }
            @include sm {
              transform: scale(0.9);
            }
            @include sp {
              transform: scale(0.7);
            }
          }
        }
      }
      &-caption {
        text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
        margin-top: 20px;
        @include sp {
          margin-top: 1em;
          font-size: 1.4rem;
        }
      }
    }
  }
}
