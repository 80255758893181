.footer {
  background: $color-primary;
  color: #fff;
  position: relative;
  z-index: 2;
  padding: 30px 0 20px;
  @include lg {
    padding: calc(30/1920*100vw) 0 calc(20/1920*100vw);
  }
  @include sp {
    padding: 40px 0 30px;
  }
  @include xs {
    padding: calc(40/375*100vw) 0 calc(30/375*100vw)
  }
  &-container {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    @include flex(center,center,nowrap,column);
    @include lg {
      width: calc(300/1920*100vw);
    }
    @include sp {
      width: 242px;
      min-width: 242px;
    }
    @include xs {
      width: calc(242/375*100vw);
      min-width: calc(242/375*100vw);
    }
  }
  &-title {
    display: block;
    width: 100%;
    a {
      width: 100%;
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
      display: block;
      cursor: pointer;
      @include lg {
        padding-bottom: calc(15/1920*100vw);
      }
      span {
        letter-spacing: 0.01em;
        font-size: 1.2rem;
        text-align: center;
        display: block;
      }
      img {
        width: 100%;
        transition: all 0.4s
      }
      &:hover,
      &:focus {
        img {
          opacity: 0.7;
        }
      }
    }
  }
  &-links {
    @include flex(center,flex-start,nowrap,null);
    width: 100%;
    margin: 20px auto 30px;
    li {
      margin: 0 10px;
    }
    @include lg {
      margin: calc(20/1920*100vw) auto calc(30/1920*100vw);
      li {
        margin: 0 calc(10/1920*100vw);
      }
    }
    @include sp {
      margin-top: 16px;
    }
    @include xs {
      margin-top: calc(16/375*100vw);
      li {
        margin: 0 calc(10/375*100vw);
      }
    }
    a {
      width: 110px;
      display: block;
      @include lg {
        width: calc(110/1920*100vw);
      }
      @include sp {
        width: 90px;
        min-width: 90px;
      }
      @include xs {
        width: calc(90/375*100vw);
        min-width: calc(90/375*100vw);
      }
      img {
        width: 100%;
        transition: all 0.4s;
      }
      &:hover,
      &:focus {
        img {
          opacity: 0.7;
        }
      }
    }
  }
  &-copyright {
    font-size: 1rem;
    letter-spacing: 0.01em;
    cursor: default;
  }

  &-fixed {
    overflow: hidden;
    background: $color-primary;
    border-radius: 50%;
    z-index: 10;
    color: #fff;
    width: 90px;
    height: 90px;
    cursor: pointer;
    @include fixed(null,20px,20px,null);
    @include flex(center,center,nowrap,column);
    span {
      margin-top: 8px;
      font-weight: bold;
    }
    
    @include lg {
      width: calc(90/1920*100vw);
      height: calc(90/1920*100vw);  
      right: calc(20/1920*100vw);
      bottom: calc(20/1920*100vw);
      img {
        width: calc(35/1920*100vw);
      }
      span {
        margin-top: calc(8/1920*100vw);
      }
    }

    @include sp {
      width: 145px;
      height: 48px;
      bottom: 10px;
      right: 10px;
      border-radius: 25px;
      flex-direction: row;
      transition: all 0.4s;

      span {
        margin: 0 0 0 10px;
        font-size: 1.2rem;
        font-weight: 500;
      }
      img {
        width: 24px;
      }
      &:before,
      &:after {
        display: none;
      }
      &.min {
        width: 48px;
        span {
          display: none;
        }
      };
    }

    @include xs {
      width: calc(145/375*100vw);
      height: calc(48/375*100vw);
      bottom: calc(10/375*100vw);
      right: calc(10/375*100vw);
      border-radius: calc(25/375*100vw);
      span {
        margin: 0 0 0 calc(10/375*100vw)
      }
      img {
        width: calc(24/375*100vw);
      }
      &.min {
        width: calc(48/375*100vw);
      }
    }

    &:before,
    &:after {
      content: "";
      @include absolute(0,null,null,0);
      background: rgba(255,255,255,0.1);
      border-radius: 50%;
    }
    &:before {
      width: 0;
      height: 100%;
    }
    &:after {
      width: 100%;
      height: 100%;
      transform: translateY(100%);
    }
    &:hover, 
    &:focus {
      &::before {
        animation: fillLeft 1.5s infinite;
      }
      &::after {
        animation: fillTop 1.5s infinite;
      }
    }
  }
}

@keyframes fillLeft {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes fillTop {
  0% {
    transform: translate(100%,-100%);
  }
  50% {
    transform: translate(0,0);
  }
  100% {
    transform: translate(0,0);
    opacity: 0;
  }
}