.monitor {
  position: relative;
  width: 100%;
  height: 420px;
  // max-height: calc(1080/1920*100vw);
  z-index: 1;
  margin-top: 200px;
  @include lg {
    height: calc(420 / 1920 * 100vw);
    margin-top: calc(200 / 1920 * 100vw);
  }
  @include md {
    margin-top: 300px;
  }
  @include sm {
    margin-top: 490px;
    height: 690px;
  }
  @include sp {
    height: 480px;
    // margin-top: 600px;
  }
  @include xs {
    height: calc(480 / 375 * 100vw);
  }
  &-container {
    width: 1300px;
    max-width: 100%;
    padding: 0 20px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    @include lg {
      width: calc(1360 / 1920 * 100vw);
    }
    @include xs {
      padding: 0 calc(20 / 375 * 100vw);
    }
  }

  &-wrap {
    text-align: right;
    height: 100%;
    @include flex(flex-end, center, wrap, null);
    opacity: 0;
    transform: translateX(30%);
    transition: all 1.5s;
    &.active {
      opacity: 1;
      transform: translateX(0);
    }
    @include md {
      align-items: flex-start;
      align-content: flex-start;
    }
    @include sp(1280px) {
      align-content: flex-end;
    }
  }

  &-description {
    width: 100%;
    margin-right: -1em;
  }

  &-content {
    @include absolute(50%, null, null, 40px);
    transform: translateY(-50%);
    width: 750px;
    max-width: 100%;
    height: 420px;
    @include lg {
      width: calc(750 / 1920 * 100vw);
      height: calc(420 / 1920 * 100vw);
      left: calc(40 / 1920 * 100vw);
    }
    @include md {
      top: auto;
      bottom: 0;
      transform: translateY(0);
    }
    @include sm {
      height: 500px;
    }
    @include sm {
      top: 0;
      bottom: auto;
      left: auto;
      right: 40px;
    }
    @include sp {
      top: 0;
      left: auto;
      right: -90px;
      transform: none;
      width: 450px;
      height: 260px;
    }
    @include xs {
      right: calc(-90 / 375 * 100vw);
      width: calc(450 / 375 * 100vw);
      height: calc(260 / 375 * 100vw);
    }
    figure {
      img {
        order: 2;
      }
      figcaption {
        font-size: 1.3rem;
        font-weight: bold;
        order: 1;
        margin-bottom: 6px;
        @include sp {
          font-size: 1rem;
        }
      }
    }
    &-scoreboard {
      width: 590px;
      position: relative;
      z-index: 1;
      opacity: 0;
      transition: all 2.5s;
      @include lg {
        width: calc(590 / 1920 * 100vw);
      }
      @include lg(768px) {
        transform: translateY(30%);
      }
      @include sp(1280px) {
        @include absolute(0, 0, null, null);
      }
      @include sp {
        width: 335px;
        transform: translateX(-30%);
      }
      @include xs {
        width: calc(335 / 375 * 100vw);
      }

      &.active {
        transform: translate(0, 0);
        opacity: 1;
      }
      &-score {
        width: 30px;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        text-align: center;
        @include roboto;
        @include absolute(52px, null, null, 192px);
        z-index: 2;
        > * {
          padding: 5px 0;
        }
        @include lg {
          width: calc(30 / 1920 * 100vw);
          top: calc(50 / 1920 * 100vw);
          left: calc(192 / 1920 * 100vw);
          > * {
            padding: calc(5 / 1920 * 100vw) 0;
          }
        }
        @include sp {
          width: 20px;
          min-width: auto;
          font-size: 1rem;
          top: 33px;
          left: 107px;
          > * {
            padding: 3px 0;
          }
        }
        @include xs {
          width: calc(20 / 375 * 100vw);
          top: calc(33 / 375 * 100vw);
          left: calc(107 / 375 * 100vw);
          > * {
            padding: calc(3 / 375 * 100vw) 0;
          }
        }
      }
      &-image {
        @include flex(flex-start, center, wrap, null);
        position: relative;
        z-index: 1;
      }
    }

    &-courtside {
      width: 400px;
      @include absolute(null, 0, 0, null);
      z-index: 2;
      opacity: 0;
      transition: all 2s;
      @include lg {
        width: calc(400 / 1920 * 100vw);
      }
      @include lg(768px) {
        transform: translateY(-30%);
      }
      @include sp(1280px) {
        right: auto;
        left: 0;
      }
      @include sp {
        width: 250px;
        transform: translateX(30%);
      }
      @include xs {
        width: calc(250 / 375 * 100vw);
      }
      &.active {
        transform: translate(0, 0);
        opacity: 1;
      }
      &-score {
        font-size: 6.2rem;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        @include absolute(52px, 40px, null, null);
        font-weight: bold;
        color: #fff;
        text-align: center;
        width: 68px;
        > * {
          padding: 8px 0;
        }
        .playerB {
          margin-top: 12px;
        }
        @include lg {
          top: calc(52 / 1920 * 100vw);
          right: calc(40 / 1920 * 100vw);
          width: calc(68 / 1920 * 100vw);
          > * {
            padding: calc(8 / 1920 * 100vw) 0;
          }
        }
        @include sm {
          top: 35px;
        }
        @include sp {
          font-size: 3.8rem;
          top: 23px;
          right: 10px;
          > * {
            padding: 3px 0;
          }
        }
        @include xs {
          top: calc(23 / 375 * 100vw);
          right: calc(10 / 375 * 100vw);
          > * {
            padding: calc(3 / 375 * 100vw) 0;
          }
        }
      }
      &-image {
        @include flex(flex-end, center, wrap, null);
        order: 2;

        figcaption {
          margin-right: 1em;
        }
        @include sp(1280px) {
          justify-content: flex-start;
          img {
            order: 1 !important;
          }
          figcaption {
            margin: 0 0 0 0.5em;
            order: 2;
          }
        }
      }
    }
  }
}
