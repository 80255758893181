.admin {
  margin-top: 200px;
  position: relative;
  width: 100%;
  height: 530px;
  @include lg {
    margin-top: calc(200 / 1920 * 100vw);
    height: calc(530 / 1920 * 100vw);
  }
  @include sm {
    height: 800px;
  }
  // @include sm(768px,1024px) {
  //   height: calc(1180/1024*100vw);
  // }

  @include sp {
    height: 490px;
    margin-top: 160px;
  }
  @include xs {
    height: calc(490 / 375 * 100vw);
    margin-top: calc(160 / 375 * 100vw);
  }
  &-container {
    height: 100%;
    @include flex(flex-start, center, nowrap, null);
    @include md {
      align-items: flex-start;
    }
    @include sp {
      align-items: flex-end;
    }
  }
  &-wrap {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 1.5s;
    &.active {
      opacity: 1;
      transform: translateX(0);
    }
    strong {
      color: $color-primary;
    }
  }
  &-content {
    width: 810px;
    max-width: 100%;
    height: 530px;
    @include absolute(50%, 0, null, null);
    transform: translateY(-50%);
    @include lg {
      width: calc(810 / 1920 * 100vw);
      height: calc(530 / 1920 * 100vw);
    }
    @include sm {
      transform: translateY(-40%);
      top: auto;
      bottom: 0;
    }
    @include sm(768px, 1024px) {
      transform: unset;
    }
    @include sp {
      width: 400px;
      min-width: 400px;
      height: 234px;
      min-height: auto;
      top: 0;
      transform: unset;
    }
    @include xs {
      width: calc(400 / 375 * 100vw);
      min-width: calc(400 / 375 * 100vw);
      height: calc(234 / 375 * 100vw);
    }
    &-image {
      max-width: 100%;
    }
    .laptop {
      width: 500px;
      max-width: 100%;
      @include absolute(null, null, 0, 0);
      z-index: 2;
      transform: translateX(10%);
      opacity: 0;
      transition: all 1.5s;
      &.active {
        transform: translateX(0);
        opacity: 1;
      }
      @include lg {
        width: calc(500 / 1920 * 100vw);
      }
      @include sp {
        width: 100%;
        min-width: auto;
        top: 0;
        right: -72px;
        bottom: auto;
        left: auto;
      }
      @include xs {
        right: calc(-72 / 375 * 100vw);
      }
    }
    .desktop {
      width: 600px;
      max-width: 100%;
      @include absolute(0, -12%, null, null);
      transform: translateY(17%);
      opacity: 0;
      transition: all 2.5s;
      &.active {
        opacity: 1;
        transform: translateY(0);
      }
      @include lg {
        width: calc(600 / 1920 * 100vw);
      }
      @include sp {
        display: none;
      }
    }
  }
}
