.web {
  position: relative;
  margin-top: 260px;
  @include lg {
    margin-top: calc(260 / 1920 * 100vw);
  }
  @include sp {
    margin-top: 60px;
    height: 560px;
  }
  @include xs {
    margin-top: calc(60 / 375 * 100vw);
    height: calc(560 / 375 * 100vw);
  }
  &-container {
    height: 100%;
  }
  &-content {
    position: relative;
    width: 790px;
    max-width: 100%;
    margin: 0 auto;
    @include lg {
      width: calc(790 / 1920 * 100vw);
    }
    @include sp {
      width: 500px;
      min-width: 500px;
      height: 356px;
      @include absolute(0, null, null, -125px);
    }
    @include xs {
      width: calc(500 / 375 * 100vw);
      min-width: calc(500 / 375 * 100vw);
      height: calc(356 / 375 * 100vw);
      @include absolute(0, null, null, calc(-125 / 375 * 100vw));
    }
    &-laptop {
      position: relative;
      z-index: 1;
      width: 750px;
      margin: 0 auto;
      opacity: 0;
      transform: translateX(-20%);
      transition: all 1.5s;
      &.active {
        opacity: 1;
        transform: translateX(0);
      }
      @include lg {
        width: calc(750 / 1920 * 100vw);
      }
      @include sp {
        width: 480px;
        @include absolute(0, null, null, 0);
      }
      @include xs {
        width: calc(480 / 375 * 100vw);
      }
      &-inner {
        z-index: 2;
        width: 580px;
        height: 340px;
        overflow: hidden;
        @include absolute(52.5px, null, null, 50%);
        transform: translateX(-50%);
        @include lg {
          width: calc(580 / 1920 * 100vw);
          height: calc(340 / 1920 * 100vw);
          top: calc(52.5 / 1920 * 100vw);
        }
        @include sp {
          width: 372px;
          height: 217px;
          top: 34.5px;
          img {
            height: 404px;
          }
        }
        @include xs {
          width: calc(372 / 375 * 100vw);
          height: calc(217 / 375 * 100vw);
          top: calc(34.5 / 375 * 100vw);
          img {
            height: calc(404 / 375 * 100vw);
          }
        }
        img {
          width: 100%;
          animation: laptopAnimate 5s infinite alternate ease-in-out;
        }
      }
      &-image {
        position: relative;
        z-index: 1;
      }
    }
    &-mobile {
      @include absolute(155px, 0, null, null);
      width: 240px;
      z-index: 2;
      opacity: 0;
      transition: all 2s;
      @include lg {
        width: calc(240 / 1920 * 100vw);
        top: calc(155 / 1920 * 100vw);
      }
      @include lg(768px) {
        transform: translateX(30%);
      }
      @include sp {
        width: 160px;
        top: 60px;
        transform: translateY(20%);
      }
      @include xs {
        width: calc(160 / 375 * 100vw);
        top: calc(60 / 375 * 100vw);
      }
      &.active {
        transform: translate(0, 0);
        opacity: 1;
      }
      &-hand {
        @extend .tablet-content-hands-icon;
        @include absolute(275px, 0, null, null);
        height: 95px;
        animation: mobileHand 4s infinite;
        z-index: 3;
        &:after {
          width: 80px;
          height: 96px;
          transform: scaleX(-1);
        }
        &:before {
          display: none;
        }
        @include lg {
          height: calc(95 / 1920 * 100vw);
          top: calc(275 / 1920 * 100vw);
          &:after {
            width: calc(80 / 1920 * 100vw);
            height: calc(96 / 1920 * 100vw);
          }
        }
        @include sp {
          top: 183px;
          width: 50px;
          height: 48px;
          &:after {
            width: 33px;
            height: 48px;
          }
        }
      }
      &-inner {
        @include absolute(1.5%, null, null, 50%);
        transform: translateX(-51.9%);
        z-index: 2;
        width: 184px;
        height: 100%;
        @include lg {
          width: calc(184 / 1920 * 100vw);
        }
        @include sp {
          width: 123px;
        }
        @include xs {
          width: calc(123 / 375 * 100vw);
        }
        > * {
          width: 100%;
        }
        .image {
          &-default {
            @include absolute(3px, null, null, 0);
            mask: url("../images/web_mask.svg") 50% 81% no-repeat;
            mask-size: contain;
            animation: mobileSlideDown 4s infinite;
            @include lg {
              top: calc(3 / 1920 * 100vw);
            }
            @include sp {
              img {
                height: 310px;
              }
            }
            @include xs {
              img {
                height: calc(310 / 375 * 100vw);
              }
            }
          }
          &-before {
            position: relative;
            z-index: 3;
            height: 224px;
            margin-top: 47px;
            overflow: hidden;
            img {
              width: 100%;
              @include absolute(-47px, null, null, 0);
            }
            @include lg {
              margin-top: calc(47 / 1920 * 100vw);
              height: calc(224 / 1920 * 100vw);

              img {
                top: calc(-47 / 1920 * 100vw);
              }
            }
            @include sp {
              height: 150px;
              margin-top: 32px;
              top: 0;
              img {
                height: 310px;
                top: -31px;
              }
            }
            @include xs {
              height: calc(150 / 375 * 100vw);
              min-height: calc(150 / 375 * 100vw);
              margin-top: calc(32 / 375 * 100vw);
              img {
                top: calc(-31 / 375 * 100vw);
                height: calc(310 / 375 * 100vw);
              }
            }
          }
          &-after {
            @include absolute(0, null, null, 50%);
            transform: translateX(-50%);
            z-index: 1;
            mask: url("../images/web_mask_detail.svg") 50% 59% no-repeat;
            mask-size: contain;
            animation: detailSlideDown 4s infinite;
          }
        }
      }
    }
  }
  &-wrap {
    margin-top: 65px;
    opacity: 0;
    transition: all 2.5s;
    transform: translateY(25%);
    @include lg {
      margin-top: calc(65 / 1920 * 100vw);
    }

    @include sp {
      margin-top: 0;
      @include absolute(null, null, 0, 20px);
      transform: translateY(-20%);
    }
    @include xs {
      left: calc(20 / 375 * 100vw);
    }
    &.active {
      transform: translateY(0%);
      opacity: 1;
    }
  }
}

@keyframes laptopAnimate {
  0%,
  20% {
    transform: translateY(0);
  }
  80%,
  100% {
    transform: translateY(-46%);
  }
}

@keyframes mobileHand {
  0% {
    transform: translate(100%, 100%);
  }
  20%,
  40% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(0, 0) rotate(-5deg);
  }
}

@keyframes detailSlideDown {
  0%,
  30% {
    transform: translate(-50%, -12%);
    z-index: 1;
    opacity: 0;
  }
  50%,
  100% {
    transform: translate(-50%, 0);
    z-index: 2;
    opacity: 1;
  }
}

@keyframes mobileSlideDown {
  0%,
  30% {
    transform: translate(0);
    mask-position: 50% 81%;
    z-index: 2;
  }
  50%,
  100% {
    transform: translate(0, 13%);
    mask-position: 50% 62%;
    z-index: 1;
  }
}
