.caution {
  padding: 0 0 140px;
  background: #fff;
  position: relative;
  z-index: 2;
  @include sp {
    padding: 60px 0;
  }
  @include xs {
    padding: calc(60/375*100vw) 0;
  }
  &-list {
    padding-top: 40px;
    border-top: 1px solid #BFBFBF;
    li {
      @include flex(flex-start,flex-start,nowrap,null);
      font-size: 1.2rem;
      line-height: calc(20/12);
      color: #6C6C6C;
      &:before {
        content: "・";
        margin-right: 0.5em;
      }
    }
    @include sp {
      padding-top: 20px;
    }
    @include xs {
      padding-top: calc(20/375*100vw);
    }
  }
}