.main {
  position: relative;
  width: 100%;
  height: calc(1080/1920*100vw);
  min-height: 100vh;
  color: #fff;
  z-index: 2;
  @include sp {
    height: calc(667/375*100vw);
    max-height: 100vh;
  }
  &-background {
    @include fixed(null,0,0,null);
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: url(../images/main_background.jpg) right bottom no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
    @include sp {
      height: calc(667/375*100vw);

      background-image: url(../images/main_background_sp.jpg);
    }
  }
  &-container {
    @include absolute(50%,null,null,50%);
    transform: translate(-50%, -50%);
    font-weight: 700;
    letter-spacing: 0.01em;
    z-index: 10;
    transition: all 0.4s;
  }
  &-title {
    font-size: 4.8rem;
    line-height: calc(60/48);
    @include sm {
      font-size: 3.6rem;
    }
    @include sp {
      font-size: 2.4rem;
    }
    > span {
      position: relative;
      z-index: 2;
      &:before {
        content: "";
        // width: 100%;
        width: 0;
        height: 90%;
        @include absolute(50%,0,null,0);
        transform: translateY(-45%);
        z-index: -1;
      }
    }
    &-style01 {
      &:before {
        background-color: #000;
        animation: fillToRight .5s 2.5s ease-in-out forwards;
      }
    }
    &-style02 {
      &:before {
        background-color: $color-primary;
        animation: fillToRight .5s 2.8s ease-in-out forwards;
      }
    }
    &-style03 {
      &:before {
        background-color: $color-secondary;
        animation: fillToRight .8s 3.1s ease-in-out forwards;
      }
    }
  }
  &-description {
    margin: 30px 0 0;
    font-size: 2.4rem;
    line-height: calc(36/24);
    text-shadow: 3px 3px 5px rgba(0,0,0,0.1);
    @include sm {
      font-size: 2rem;
    }
    @include sp {
      font-size: 1.4rem;
    }
  }
}

@keyframes fillToRight {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}