.tablet {
  margin-top: 260px;
  z-index: 2;
  @include lg {
    margin-top: calc(360 / 1920 * 100vw);
  }
  // @include md {
  //   margin-top: 450px;
  // }
  @include sp {
    margin-top: 160px;
  }
  @include xs {
    margin-top: calc(160 / 375 * 100vw);
  }
  &-container.bigger {
    transform: scale(1.1);
  }
  &-content {
    &-hands {
      @include absolute(null, null, 21%, 50%);
      width: 100%;
      transform: translateX(-50%);
      z-index: 4;
      display: none;
      @include sp {
        width: 110%;
        bottom: 25%;
      }
      .bigger & {
        &.active {
          @include flex(space-between, center, nowrap, null);
        }
        &.hidden {
          display: none !important;
        }
      }

      &-icon {
        position: relative;
        height: calc(90 / 1920 * 100vw);
        width: calc(80 / 1920 * 100vw);
        min-height: 90px;
        min-width: 80px;
        overflow: hidden;
        // &.hidden {
        //   display: none;
        // }
        @include sp {
          width: 75px;
          min-width: 75px;
          height: 60px;
          min-height: 60px;
        }
        @include xs {
          width: calc(75 / 375 * 100vw);
          min-width: calc(75 / 375 * 100vw);
          height: calc(60 / 375 * 100vw);
          min-height: calc(60 / 375 * 100vw);
        }
        &:before {
          content: "Click";
          color: #fff;
          background: $color-primary;
          border-radius: 50%;
          padding: 1.6em 0.7em;
          @include absolute(0, null, null, null);
          z-index: 1;
          line-height: 1;
          font-size: 1.1rem;
          @include roboto;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          opacity: 0;
          animation: visible 3s infinite ease-in;
          @include sp {
            content: "Tap";
            padding: 1.1em 0.7em;
            text-transform: unset;
            top: 13%;
            // top: auto;
            // bottom: 0;
            // font-size: 1rem;
          }
        }
        &:after {
          content: "";
          width: calc(54 / 1920 * 100vw);
          min-width: 54px;
          height: calc(77 / 1920 * 100vw);
          min-height: 77px;
          background: url(../../assets/images/icons/icon_hand.svg) center center no-repeat;
          background-size: contain;
          display: block;
          filter: drop-shadow(7px 7px 0 rgba(0, 0, 0, 0.1));
          @include absolute(null, null, 0, null);
          z-index: 2;
          @include sp {
            width: 50px;
            min-width: 50px;
            height: 60px;
            min-height: 60px;
            bottom: auto;
            top: 0;
          }
          @include xs {
            width: calc(50 / 375 * 100vw);
            min-width: calc(50 / 375 * 100vw);
            height: calc(60 / 375 * 100vw);
            min-height: calc(60 / 375 * 100vw);
          }
        }
        .active & {
          &.left {
            transform: translateX(20%);
            animation: slideToRight 3s infinite cubic-bezier(0.08, -0.03, 0.06, 1);
            &:after {
              right: 0;
            }
            @include sp {
              &:before {
                left: auto;
                right: 0;
              }
              &:after {
                right: auto;
                left: 0;
              }
            }
          }
          &.right {
            transform: translateX(-20%);
            animation: slideToLeft 3s infinite cubic-bezier(0.08, -0.03, 0.06, 1);
            &:before {
              right: 0;
            }
            &:after {
              transform: scaleX(-1);
              left: 0;
            }
            @include sp {
              &:before {
                left: 0;
                right: auto;
              }
              &:after {
                right: 0;
                left: auto;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideToRight {
  0% {
    transform: translateX(-70%);
    opacity: 0;
  }
  75% {
    transform: translateX(20%) rotate(10deg);
    opacity: 1;
  }
  65%,
  85%,
  100% {
    transform: translateX(20%);
    opacity: 1;
  }
}

@keyframes slideToLeft {
  0% {
    transform: translateX(70%);
    opacity: 0;
  }
  85% {
    transform: translateX(-20%) rotate(-10deg);
    opacity: 1;
  }
  75%,
  95%,
  100% {
    transform: translateX(-20%);
    opacity: 1;
  }
}

@keyframes visible {
  0%,
  65%,
  75% {
    opacity: 0;
  }
  70%,
  80%,
  100% {
    opacity: 1;
  }
}
