.floating {
  @include fixed(null, 0, 0, null);
  @include flex(center, flex-start, nowrap, column);
  gap: 2rem;
  z-index: 102;
  border-radius: 2rem 0 0 0;
  filter: drop-shadow(-0.5rem -0.5rem 1rem rgba(0, 0, 0, 0.2));
  transform: translateZ(0);
  color: #fff;
  background: $color-primary;
  overflow: hidden;
  padding: 3rem 2.5rem 2rem;
  width: auto;
  max-width: 46rem;
  transform: translateY(0);
  transition: transform 0.4s;
  &:before {
    content: "";
    width: 25%;
    height: 100%;
    background: #fff;
    opacity: 0.05;
    @include absolute(0, null, null, 0);
    z-index: 1;
  }
  &:after {
    content: "";
    width: 20rem;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    clip-path: polygon(0 100%, 100% 100%, 0 0);
    @include absolute(0, null, null, 25%);
    z-index: 1;
  }

  @include sp {
    max-width: 100%;
    border-radius: 2rem 2rem 0 0;
  }

  &-header {
    width: 100%;
    position: relative;
    @include flex(space-between, center, nowrap, null);
  }

  &-livescore {
    @include flex(flex-start, center, nowrap, null);
    gap: 1rem;
    &-icon {
      width: 2rem;
      height: 100%;
      fill: $color-secondary;
    }
    &-title {
      padding: 0.5rem 0;
      width: 55%;
      fill: yellow;
    }
  }

  &-close {
    border: none;
    outline: 0;
    background: transparent;
    width: 3rem;
    height: 3rem;
    padding: 0;
    &-icon {
      width: 100%;
      height: 100%;
      fill: white;
    }
  }

  &-container {
    @include flex(center, flex-start, nowrap, column);
    gap: 1rem;
    position: relative;
    z-index: 2;
    width: 100%;
    & + .floating-container {
      margin-top: 1rem;
      padding-top: 2rem;
      border-top: 1px dashed white;
    }

    @include sp {
      &:nth-of-type(n + 4) {
        display: none;
      }
    }
  }

  &-title {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: bold;
  }
  &-information {
    @include flex(center, flex-start, nowrap, column);
    font-size: 1.4rem;
    gap: 1rem;
    line-height: 1.5;
    > p {
      @include flex(flex-start, flex-start, nowrap, null);
      gap: 1em;
      white-space: pre-wrap;
      &:before {
        font-size: 1.2rem;
        background: white;
        color: black;
        padding: 0.5em 1em;
        line-height: 1;
        min-width: 7.2rem;
        box-sizing: border-box;
      }
    }
    &-date {
      &:before {
        content: "開催日時";
      }
    }
    &-place {
      &:before {
        content: "開催場所";
      }
    }
  }

  &-list {
    @include flex(flex-start, flex-start, wrap, null);
    gap: 1rem 1.5rem;
    margin-top: 1rem;
    width: 100%;
    &-item {
      width: calc(50% - 0.75rem);
      &-link {
        @include flex(space-between, center, nowrap, null);
        padding: 0.8em 1em 0.8em 1.5em;
        font-size: 1.4rem;
        background: white;
        color: $color-primary;
        border-radius: 2rem;
        font-weight: bold;
        position: relative;
        overflow: hidden;
        &:before {
          content: "";
          width: 0;
          height: 100%;
          @include absolute(0, null, null, 0);
          background: yellow;
          border-radius: 2rem;
          z-index: 1;
          transition: width 0.4s;
        }

        &:after {
          content: "";
          width: 7px;
          height: 12px;
          @include linkArrow("1F318E");
          transition: all 0.4s;
          background-size: contain;
          display: block;
          z-index: 2;
          @include lg {
            width: calc(7 / 1920 * 100vw);
            height: calc(12 / 1920 * 100vw);
          }
          @include xs {
            width: calc(7 / 375 * 100vw);
            height: calc(12 / 375 * 100vw);
            min-width: calc(7 / 375 * 100vw);
            height: calc(12 / 375 * 100vw);
          }
        }

        &:hover:before {
          width: 100%;
        }
      }

      &.full {
        margin: 1rem auto 0;
        width: 75%;

        a {
          font-size: 1.6rem;
        }
      }

      &-title {
        position: relative;
        z-index: 2;
      }
    }
  }
  &-wrapper {
    @include fixed(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 101;
    display: block;
  }

  &.hidden {
    transform: translateX(100%);
    transition: transform 0.4s;

    @include sp {
      transform: translateY(100%);
    }
  }

  &-button {
    background: white;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    @include fixed(null, 2rem, 13rem, null);
    @include flex(center, center, nowrap, column);
    gap: 0.5rem;
    cursor: pointer;
    z-index: 99;
    overflow: hidden;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.1));

    &:before,
    &:after {
      content: "";
      @include absolute(0, null, null, 0);
      background: $color-secondary;
      opacity: 0.1;
      border-radius: 50%;
    }
    &:before {
      width: 0;
      height: 100%;
    }
    &:after {
      width: 100%;
      height: 100%;
      transform: translateY(100%);
    }
    &:hover,
    &:focus {
      &::before {
        animation: fillLeft 1.5s infinite;
      }
      &::after {
        animation: fillTop 1.5s infinite;
      }
    }

    @include sp {
      width: 14.5rem;
      height: 4.8rem;
      bottom: 6.8rem;
      right: 1rem;
      border-radius: 2.5rem;
      flex-direction: row;
      transition: all 0.4s;

      &.min {
        width: 4.8rem;
        .floating-button-title {
          display: none;
        }
      }

      &:before,
      &:after {
        display: none;
      }
    }

    &-icon {
      width: 3.5rem;
      fill: $color-secondary;
      animation: swing 0.4s infinite;
      @include sp {
        width: 2.5rem;
      }
    }

    &-title {
      font-size: 1.2rem;
      color: $color-secondary;
      font-weight: bold;
      text-align: center;
      @include sp {
        font-size: 1.4rem;
        br {
          display: none;
        }
      }
    }
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
}
