@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap");
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "core/reset";
@import "core/mixin";
@import "core/reset-slick";

html,
body {
  position: relative;
  overflow-x: hidden;
  @include lg {
    font-size: calc(10 / 1920 * 100vw);
  }
  @include xs {
    font-size: calc(10 / 375 * 100vw);
  }
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
}

.scroll-disable {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

.container {
  width: 1080px;
  max-width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  @include lg {
    width: calc(1080 / 1920 * 100vw);
  }
  @include sp {
    padding: 0 20px;
  }
  @include xs {
    padding: 0 calc(20 / 375 * 100vw);
  }
}

.section {
  position: relative;
  z-index: 10;
  &-title {
    font-size: 4.6rem;
    font-weight: 700;
    line-height: calc(54 / 48);
  }
  &-description {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: calc(24 / 18);
    margin-top: 30px;
  }
  @include sm {
    &-title {
      font-size: 3.6rem;
    }
  }
  @include sp {
    &-title {
      font-size: 2.4rem;
    }
    &-description {
      font-size: 1.6rem;
      margin-top: 20px;
      font-weight: 400;
    }
  }
  @include xs {
    &-description {
      margin-top: calc(20 / 375 * 100vw);
    }
  }
}

.visible {
  &-pc {
    display: block;
    @include sp {
      display: none;
    }
  }
  &-sp {
    display: none;
    @include sp {
      display: inline;
    }
  }
}

.toggleWrapper {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  @include fixed(0, null, null, 0);
  z-index: 150;
  transition: all 0.4s;
  display: block;
}

.gradient {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  position: relative;
  z-index: 3;
  // padding-bottom: 560px;
  // @include lg {
  //   padding-bottom: calc(560/1920*100vw)
  // }
  // @include sp {
  //   padding-bottom: 360px;
  // }
  // @include xs {
  //   padding-bottom: calc(360/375*100vw);
  // }
}

@import "loader";
@import "header";
@import "main";
@import "referee";
@import "admin";
@import "tablet";
@import "monitor";
@import "web";
@import "features";
@import "works";
@import "contact";
@import "caution";
@import "footer";
@import "floating";
